import {Component, Input} from '@angular/core';
import {Alert, AlertSeverity} from "../../api/model/alert";

@Component({
  selector: 'app-alert-menu-list',
  templateUrl: './alert-menu-list.component.html',
  styleUrls: ['./alert-menu-list.component.sass']
})
export class AlertMenuListComponent {
  @Input() alerts!: Alert[];
  alertLevelEnum: typeof AlertSeverity = AlertSeverity;
}
