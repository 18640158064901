<div *ngFor="let a of alerts" class="alert">
  <a [queryParams]="{eq: a.culpritEquipmentIds}"
     [routerLink]="a.project | url">
    <mat-icon *ngIf="a.severity == alertLevelEnum.CRITICAL" class="small-icon" color="warn">priority_high</mat-icon>
    <mat-icon *ngIf="a.severity == alertLevelEnum.WARNING" class="yellow small-icon">question_mark</mat-icon>
    <strong>{{ a.project.name }} </strong>
    <span>{{ a.message }}</span>
    <span *ngIf="a.culpritEquipmentIds && a.culpritEquipmentIds.length > 0"> ({{ a.culpritEquipmentIds.length }})
        </span>
  </a>
</div>
