<h1 class="flex-row equipment-add-edit-dialog-title" mat-dialog-title>
  <div class="title-text">
    {{ verb }} equipment
    <span *ngIf="data.equipment">
      {{ data.equipment.projectCode }} {{ data.equipment.manufacturer }} {{ data.equipment.sku }}
    </span>
    in project {{ data.project.name }}
  </div>
  <button [matMenuTriggerFor]="visibilityMenu" class="float-right" mat-icon-button matSuffix
          matTooltip="Show/hide columns">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #visibilityMenu="matMenu">
    <strong mat-menu-item>Show/hide columns</strong>
    <div mat-menu-item>
      <mat-checkbox [checked]="true" [disabled]="true">Main info</mat-checkbox>
    </div>
    <div mat-menu-item>
      <mat-checkbox (change)="persistColumnVisibility()" (click)="$event.stopPropagation()"
                    [(ngModel)]="visibilityOptions.dates">Status, responsible, dates, ...
      </mat-checkbox>
    </div>
    <div mat-menu-item>
      <mat-checkbox (change)="persistColumnVisibility()" (click)="$event.stopPropagation()"
                    [(ngModel)]="visibilityOptions.serialNumbers">Serial numbers
      </mat-checkbox>
    </div>
  </mat-menu>
</h1>

<div class="dense-1 equipment-add-edit-dialog-content" mat-dialog-content>
  <form (ngSubmit)="onSubmit()" [formGroup]="addEditForm" class="add-edit-equipment-form">
    <div class="column main-info">
      <div class="row">
        <mat-form-field class="no-bottom sku">
          <mat-label>SKU</mat-label>
          <input [matAutocomplete]="catalogAuto" formControlName="sku" matInput placeholder="Ex. 4240A1021-EU"
                 type="text">
        </mat-form-field>
        <mat-form-field class="no-bottom manufacturer">
          <mat-label>Manufacturer</mat-label>
          <input formControlName="manufacturer" matInput placeholder="Ex. ETC" type="text">
        </mat-form-field>
        <mat-autocomplete #catalogAuto="matAutocomplete" (optionSelected)="skuAutocompleteSelect($event)"
                          class="equipment-catalog-autocomplete" panelWidth="692px">
          <ng-container *ngIf="filteredEquipment | async as loaded">
            <mat-option *ngIf="loaded.length > 0" [disabled]="true" class="heading">
              <span class="manufacturer">Manufacturer</span>
              <span class="sku">SKU</span>
              <span class="model">Model</span>
            </mat-option>
            <mat-option *ngFor="let eq of loaded" [id]="eq.id" [value]="eq.sku">
              <span class="manufacturer">{{ eq.manufacturer }}</span>
              <span [innerHtml]="highlight(eq.sku, addEditForm.value.sku)" class="sku"></span>
              <span [innerHtml]="highlight(eq.model, addEditForm.value.model)" class="model"></span>
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </div>

      <div>
        <mat-form-field class="no-bottom model">
          <mat-label>Model</mat-label>
          <input [matAutocomplete]="catalogAuto" formControlName="model" matInput
                 placeholder="Ex. Eos Motorized Fader Wing 10"
                 type="text">
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field *ngIf="!equipmentGroupIsService" class="no-bottom project-code">
          <mat-label>Project Code</mat-label>
          <input formControlName="projectCode" matInput placeholder="Ex. 1.12" type="text">
        </mat-form-field>
        <mat-form-field class="no-bottom quantity">
          <mat-label>Quantity</mat-label>
          <input [disabled]="alsoSplit" formControlName="quantity" matInput placeholder="Ex. 5" type="number">
        </mat-form-field>
        <button (click)="alsoSplit = true; addEditForm.controls.quantity.disable()" *ngIf="data.equipment"
                [disabled]="project.locked || alsoSplit"
                mat-icon-button
                matTooltip="Split current equipment entry into multiple">
          <mat-icon>alt_route</mat-icon>
        </button>
      </div>
      <div *ngIf="alsoSplit" class="splitter">
        <label>Split {{ addEditForm.value.splitQty }}
          + {{ (addEditForm.controls.quantity.value || 0) - (addEditForm.value.splitQty || 0) }}
        </label>
        <mat-slider [max]="(addEditForm.controls.quantity.value || 0) - 1" discrete min="0" step="1">
          <input formControlName="splitQty" matSliderThumb>
        </mat-slider>
      </div>

      <div>
        <mat-form-field appearance="fill" class="product-description no-bottom">
          <mat-label>Product Description</mat-label>
          <textarea cdkAutosizeMaxRows="18" cdkAutosizeMinRows="1"
                    cdkTextareaAutosize
                    formControlName="description"
                    matInput></textarea>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="hyperlink">
          <mat-label>Product Hyperlink</mat-label>
          <input formControlName="hyperlink" matInput placeholder="Ex. https://sonus.lt/SON011543" type="url">
          <a *ngIf="addEditForm.controls.hyperlink.value" [href]="addEditForm.controls.hyperlink.value" mat-icon-button
             matSuffix matTooltip="Open link in new tab" target="_blank">
            <mat-icon>open_in_new</mat-icon>
          </a>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="fill" class="notes no-bottom">
          <mat-label>Notes</mat-label>
          <textarea cdkAutosizeMaxRows="18" cdkAutosizeMinRows="4"
                    cdkTextareaAutosize
                    formControlName="notes"
                    matInput></textarea>
        </mat-form-field>
      </div>
      <div *ngIf="alerts.length > 0" class="alerts">
        <p *ngFor="let a of alerts">
          <span class="icon">
            <mat-icon *ngIf="a.severity == alertLevels.CRITICAL" class="small-icon"
                      color="warn">priority_high</mat-icon>
            <mat-icon *ngIf="a.severity == alertLevels.WARNING" class="yellow small-icon"
                      color="warn">question_mark</mat-icon>
          </span>
          {{ a.message }}
        </p>
      </div>
      <div *ngIf="data.equipment" class="comments">
        <app-comments [forId]="data.equipment.id" for="EQUIPMENT"></app-comments>
      </div>
    </div>
    <mat-divider *ngIf="visibilityOptions.dates" [vertical]="true"></mat-divider>
    <div *ngIf="visibilityOptions.dates" class="column">

      <div class="row">
        <mat-form-field class="no-bottom status">
          <mat-label>Status</mat-label>
          <mat-select cdkFocusInitial formControlName="status">
            <mat-option *ngFor="let s of statuses" [value]="s">{{ s | readable }}</mat-option>
          </mat-select>
        </mat-form-field>
        <app-status-history *ngIf="data.equipment" [equipment]="data.equipment">
          <button (click)="$event.preventDefault()"
                  [disabled]="!data.equipment.statusHistory || data.equipment.statusHistory.length == 0"
                  mat-icon-button>
            <mat-icon>history</mat-icon>
          </button>
        </app-status-history>
      </div>

      <div class="row">
        <app-user-field [control]="addEditForm.controls.responsible"></app-user-field>
      </div>

      <div class="row">
        <mat-form-field class="no-bottom purchase-order-number">
          <mat-label>Purchase Order Number</mat-label>
          <input formControlName="purchaseOrderNumber" matInput placeholder="Ex. PN127757-3" type="text">
        </mat-form-field>
        <mat-form-field appearance="fill" class="no-bottom purchase-order-date date">
          <mat-label>Purchase Order Date</mat-label>
          <input
            #purchaseOrderDate="bsDatepicker"
            (bsValueChange)="datePickerValueChange($event, 'purchaseOrderDate')"
            bsDatepicker
            formControlName="purchaseOrderDate"
            matInput>
          <mat-datepicker-toggle (click)="purchaseOrderDate.toggle()"
                                 [class.mat-datepicker-toggle-active]="purchaseOrderDate.isOpen"
                                 matIconSuffix></mat-datepicker-toggle>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="no-bottom sales-order-confirmation-number">
          <mat-label>Sales Order Confirmation Number</mat-label>
          <input formControlName="salesOrderConfirmationNumber" matInput placeholder="Ex. PN127757-3"
                 type="text">
        </mat-form-field>
        <mat-form-field appearance="fill" class="no-bottom sales-order-confirmation-date date">
          <mat-label>SOC Date</mat-label>
          <input
            #salesOrderConfirmationDate="bsDatepicker"
            (bsValueChange)="datePickerValueChange($event, 'salesOrderConfirmationDate')"
            bsDatepicker
            formControlName="salesOrderConfirmationDate"
            matInput>
          <mat-datepicker-toggle (click)="salesOrderConfirmationDate.toggle()"
                                 [class.mat-datepicker-toggle-active]="salesOrderConfirmationDate.isOpen"
                                 matIconSuffix></mat-datepicker-toggle>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field appearance="fill" class="no-bottom estimated-ship-by-date date">
          <mat-label>Est. Ship By</mat-label>
          <input
            #estimatedShipByDate="bsDatepicker"
            (bsValueChange)="datePickerValueChange($event, 'estimatedShipByDate')"
            bsDatepicker
            formControlName="estimatedShipByDate"
            matInput>
          <mat-datepicker-toggle (click)="estimatedShipByDate.toggle()"
                                 [class.mat-datepicker-toggle-active]="estimatedShipByDate.isOpen"
                                 matIconSuffix></mat-datepicker-toggle>
        </mat-form-field>

        <mat-form-field appearance="fill" class="no-bottom received-on date">
          <mat-label>Received On</mat-label>
          <input
            #receivedOnDate="bsDatepicker"
            (bsValueChange)="datePickerValueChange($event, 'receivedOnDate')"
            bsDatepicker
            formControlName="receivedOnDate"
            matInput>
          <mat-datepicker-toggle (click)="receivedOnDate.toggle()"
                                 [class.mat-datepicker-toggle-active]="receivedOnDate.isOpen"
                                 matIconSuffix></mat-datepicker-toggle>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field class="no-bottom invoice-number">
          <mat-label>Sonus Invoice Number</mat-label>
          <input formControlName="invoiceNumber" matInput placeholder="Ex. SON0054698" type="text">
        </mat-form-field>
        <mat-form-field appearance="fill" class="no-bottom invoice-date date">
          <mat-label>Sonus Invoice Date</mat-label>
          <input
            #invoiceDate="bsDatepicker"
            (bsValueChange)="datePickerValueChange($event, 'invoiceDate')"
            bsDatepicker
            formControlName="invoiceDate"
            matInput>
          <mat-datepicker-toggle (click)="invoiceDate.toggle()"
                                 [class.mat-datepicker-toggle-active]="invoiceDate.isOpen"
                                 matIconSuffix></mat-datepicker-toggle>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field appearance="fill" class="no-bottom installed-date date">
          <mat-label>Installed Date</mat-label>
          <input
            #installedDate="bsDatepicker"
            (bsValueChange)="datePickerValueChange($event, 'installedDate')"
            bsDatepicker
            formControlName="installedDate"
            matInput>
          <mat-datepicker-toggle (click)="installedDate.toggle()"
                                 [class.mat-datepicker-toggle-active]="installedDate.isOpen"
                                 matIconSuffix></mat-datepicker-toggle>
        </mat-form-field>
        <mat-form-field appearance="fill" class="no-bottom warranty-date date">
          <mat-label>Warranty Date</mat-label>
          <input
            bsDatepicker
            formControlName="warrantyDate"
            matInput>
        </mat-form-field>
      </div>

      <div *ngIf="!equipmentGroupIsService">
        <mat-form-field class="no-bottom diff-from-contract">
          <mat-label>Differs From original Contract</mat-label>
          <mat-select formControlName="diffFromContract">
            <mat-option [value]="null"></mat-option>
            <mat-option [value]="'YES_NEEDS_ACTION'">Yes, needs action!</mat-option>
            <mat-option [value]="'YES_RESOLVED'">Yes, resolved</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <mat-divider *ngIf="visibilityOptions.serialNumbers" [vertical]="true"></mat-divider>
    <div *ngIf="visibilityOptions.serialNumbers" class="column item-column">
      <div>
        <mat-checkbox (change)="toggleSerialNumberNotAvailable($event.checked)"
                      [checked]="this.serialNumbersNotAvailable">
          Serial numbers not available for this equipment
        </mat-checkbox>
      </div>
      <div class="item-container" formArrayName="items">
        <ng-container *ngFor="let itemForm of itemsCurrentPage; let i = index">
          <div [formGroup]="itemForm" class="item-form-group">
            <mat-form-field class="no-bottom serial-number">
              <mat-label>Serial Number #{{ itemsIndex(i) + 1 }}</mat-label>
              <input (paste)="handleSerialNumberPaste($event, itemsIndex(i))"
                     formControlName="serialNumber" matInput
                     placeholder="Ex. 1.12" type="text">
            </mat-form-field>
            <mat-form-field class="no-bottom firmware-version">
              <mat-label>Firmware Ver</mat-label>
              <input formControlName="firmwareVersion" matInput placeholder="Ex. 4.12.0" type="text">
            </mat-form-field>
            <mat-button-toggle-group
              aria-label="Statuses"
              class="dense-1 statuses"
              formControlName="statuses"
              hideMultipleSelectionIndicator="true"
              multiple>
              <mat-button-toggle *ngFor="let s of itemStatuses" [matTooltip]="statusTooltip(s, itemsIndex(i))"
                                 [value]="s">{{ s | readable }}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>

        </ng-container>
      </div>
      <mat-paginator (page)="handlePageEvent($event)"
                     [length]="items.controls.length"
                     [pageSizeOptions]="[5, 10, 25]"
                     [pageSize]="itemsPageSize"
                     aria-label="Select page"
                     class="mat-elevation-z1">
      </mat-paginator>
    </div>
  </form>
</div>
<div class="equipment-add-edit-dialog-actions" mat-dialog-actions>
  <button (click)="onCancelClick()" [color]="addEditForm.dirty ? 'warn' : undefined"
          mat-button type="button">Cancel
  </button>
  <button (click)="onPrimaryClick()" [disabled]="!addEditForm.valid" color="primary" mat-raised-button type="submit">
    {{ verb }}
  </button>
  <button (click)="onDeleteClick()" *ngIf="data.equipment" class="delete" mat-button type="button">Delete</button>
  <span class="dense-minimum">
  <mat-checkbox [(ngModel)]="alsoSaveToCatalog" [disabled]="isSameAsCatalog()" color="primary"
                matTooltip="Changes to SKU, Manufacturer, Model, Product Description and Product Hyperlink fields can be also saved to Equipment Catalog">
    Save to catalog
  </mat-checkbox>
  </span>
  <mat-error *ngIf="apiError" class="api-error">
    {{ apiError }}
  </mat-error>
</div>
